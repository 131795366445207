import React from "react"
import { Link } from "gatsby"

import Image from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Covid = () => (
  <Layout>
    <SEO title="covid" />
    <h1>COVID 19</h1>
    <Image alt="covid" filename="covid.jpg"/>
    <p> Craig loves COVID more than Flight Sim</p>
  </Layout>
)

export default Covid